@font-face {
  font-family: 'Copernicus';
  src: url('/fonts/GalaxieCopernicus-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('/fonts/Inter-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Italic';
  src: url('/fonts/Inter-Italic.ttf') format('truetype');
  font-style: italic;
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url('/fonts/Inter-SemiBold.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Inter-SemiBoldItalic';
  src: url('/fonts/Inter-SemiBoldItalic.ttf') format('truetype');
  font-style: italic;
}

html,
body {
  background: #ffffff;
  font-family: 'Inter-Regular';
  font-size: 1rem;
  line-height: 1.6;
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

a {
  color: #1976d2;
  cursor: pointer;
  text-decoration: none;
}
a:focus,
a:hover {
  color: #0b4a72;
  text-decoration: underline;
}

/* Override KitKat's default font-size, which is small */
button {
  font-size: 1rem !important;
}

label {
  display: block;
  color: #687382;
}

input[type='checkbox'],
input[type='radio'] {
  height: 1.25rem;
  margin-right: 0.5rem;
  min-height: unset;
  width: 1.25rem;
}

.scrolledNav {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: #acdce5;
}
.scrolledNav::-webkit-scrollbar {
  width: 0.5rem;
}
.scrolledNav::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.7);
}
.scrolledNav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #acdce5;
}
.scrolled {
  overflow-y: auto;
  scrollbar-color: #0b4a72 #323e47;
}
.scrolled::-webkit-scrollbar {
  width: 0.5rem;
}
.scrolled::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.scrolled::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(45deg, #0b4a72, #323e47);
}

ul[class^='StyledUnorderedList'] {
  list-style-type: disc;
}

/* 
  Override KitKat's <select> control min-height.
  This is just sadness... 😭
*/
div[class$='control'] {
  min-height: 2.5rem;
}

/* 
  Override KitKat's <TableToolbar>
*/
.scrolled > div:first-child > div:first-child {
  padding: 0;
}

/* 
  Override KitKat's <StandardTable> table header
*/
div[role='table'] div[role='rowgroup']:first-child {
  background-color: #ffffff;
  border: 1px solid #dce1e6;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* 
  Override KitKat's <StandardTable> table row
*/
div[role='rowgroup']:not(:first-child) div[role='row'] {
  background-color: #ffffff;
  border-left: 1px solid #dce1e6;
  border-right: 1px solid #dce1e6;
}

/* 
  Override KitKat's <StandardTable> last table row to have bottom border radius
 */
div[role='rowgroup']:not(:first-child) div[role='row']:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/*
  Override the override of the table wrapped in a `<Card>`
*/
.card-body div[role='rowgroup']:first-child,
div[class^='SimpleContainer'] div[role='rowgroup']:first-child {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
}
.card-body div[role='rowgroup']:not(:first-child) div[role='row'],
div[class^='SimpleContainer']
  div[role='rowgroup']:not(:first-child)
  div[role='row'] {
  border-left: 0;
  border-right: 0;
}
.card-body div[role='rowgroup']:not(:first-child) div[role='row']:last-child,
div[class^='SimpleContainer']
  div[role='rowgroup']:not(:first-child)
  div[role='row']:last-child {
  border-bottom: 0;
}

/* 
  Show the right chevron icon for todos, which are table rows
 */
div[role='row']:hover svg,
div[role='row']:focus svg {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.nav-list-item a:hover,
.nav-list-item a:focus {
  text-decoration: none;
}

.nav-footer {
  gap: 0.5rem;
}

.nav-footer .nav-list-text {
  line-height: 1.5;
  max-width: 10rem;
  overflow: unset;
  white-space: unset;
}

.absolute {
  position: absolute;
}
